import React from "react";

const Icon3 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
    >
      <path
        d="M38 38C17.5655 38 1 21.4345 1 1H13.3333C13.3333 14.623 24.377 25.6667 38 25.6667C51.623 25.6667 62.6667 14.623 62.6667 1H75C75 21.4345 58.4345 38 38 38ZM38 38C17.5655 38 1 54.5655 1 75H13.3333C13.3333 61.377 24.377 50.3333 38 50.3333C51.623 50.3333 62.6667 61.377 62.6667 75L75 75C75 54.5655 58.4345 38 38 38ZM38 13.3333C31.1885 13.3333 25.6667 7.81151 25.6667 1L50.3333 1C50.3333 7.81151 44.8115 13.3333 38 13.3333ZM38 62.6667C31.1885 62.6667 25.6667 68.1885 25.6667 75H50.3333C50.3333 68.1885 44.8115 62.6667 38 62.6667Z"
        stroke="#020208"
        strokeWidth="1.5"
        className="svg-elem-1"
      ></path>
    </svg>
  );
};

export default Icon3;
