import React from "react";

const Icon = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 75C58.4345 75 75 58.4345 75 38C75 17.5655 58.4345 1 38 1C17.5655 1 1 17.5655 1 38C1 58.4345 17.5655 75 38 75ZM38 75C51.623 75 62.6667 63.9564 62.6667 50.3333C62.6667 36.7103 51.623 25.6667 38 25.6667C24.377 25.6667 13.3333 36.7103 13.3333 50.3333C13.3333 63.9564 24.377 75 38 75ZM38 75C26.6475 75 17.4444 65.797 17.4444 54.4444C17.4444 43.0919 26.6475 33.8889 38 33.8889C49.3525 33.8889 58.5556 43.0919 58.5556 54.4444C58.5556 65.797 49.3525 75 38 75Z"
        stroke="#020208"
        strokeWidth="1.5"
        className="svg-elem-1"
      ></path>
    </svg>
  );
};

export default Icon;
